.sea {
    margin: 15px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search{
    font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;;
    background-color: rgb(15, 46, 75);
    font-style: italic;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 4px;
    width: fit-content;
    
    font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;;
    background: rgb(51, 71, 158);
    cursor: pointer;
    transition-duration: 0.4s;
    font-weight: lighter;
    font-size: 20px;
}