.container {
    background-image: url(https://es.web.img3.acsta.net/pictures/18/10/31/17/34/2348073.jpg);
    background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.title{
  font-size: 40px;
  border:  solid rgba(167, 171, 172, 0.959);
  height: auto;
  background-color: rgba(16, 17, 20, 0.856);
  text-align: center;
  padding: 2px;
  transition-duration: 0.4s;
justify-content: space-evenly;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: #66fcf1;
    text-shadow: -2px -2px 2px #000000, 2px -2px 2px #000000, -2px 2px 2px #000000,
      2px 2px 2px #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
    
    text-align: center;
    line-height: 1.1em;
    
}
.carg{
    background-color: darkslategrey;
    color: #66fcf1;
    padding-top: 1px;
    cursor: pointer;
    text-align: center;
    height: auto;
   
    transition-duration: 0.8s;
    border-radius: 0 25px 25px 0;
}
.az{
    background-color: darkslategrey;
    color: #66fcf1;
    padding-top: 1px;
    cursor: pointer;
    text-align: center;
    height: auto;

    transition-duration: 0.8s;
    border-radius: 25px 0 0 25px;
}

.rick {
  background-color: darkslategrey;
  color: #66fcf1;
  padding-top: 1px;
  cursor: pointer;
  text-align: center;
  height: auto;

  transition-duration: 0.8s;
  border-radius: 25px 0 0 25px;
  
}

