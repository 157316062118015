.Card{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 1rem; 
    color: white;
    border: 1px solid;
    border-radius: 50px;
    -webkit-box-shadow: 0px 5px 15px 3px rgba(128,128,128,0.45);
    -moz-box-shadow: 0px 5px 15px 3px rgba(128,128,128,0.45);
    box-shadow: 0px 5px 15px 3px rgba(128,128,128,0.45);
    background-color:#9b86a1a8;
    width: 300px;
    transition: .4s;
    height: 380px;
     float: left;
}
.name{
    height: 10%;
    color: #66fcf1;
    text-shadow: 2px 2px 6px rgb(90, 71, 10); 
    font-size:1.3rem;
    
}
.genres{
 text-align: center;
 color: #66fcf1;
 text-shadow: -2px -2px 2px #000000, 2px -2px 2px #000000, -2px 2px 2px #000000,
    2px 2px 2px #000000;
 text-transform: uppercase;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
 
}
.rick{
    font-size: 15px;
    text-align: center;
    color: #66fcf1;
}
.img{
    width: 240px;
    height: 240px;
    border-radius: 9px;
    box-shadow: 0px 0px 5px 5px rgb(12, 12, 12);
    border-radius: 10px;
    
}