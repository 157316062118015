.nave{ 
    color:rgb(25, 178, 184);
  font-size: 14px;
  font-family:"Open Sans", sans-serif;
  border-radius: 16px;
  border-width: 0px;
  background-color: #e5e5e5;
  border: 1px solid rgba(23, 172, 172, 0.589);
  height: 25px;
  white-space: nowrap;
  align-items: center;
}
.pagina{
    text-decoration: none;
  display: inline-block;
  padding: 30px;
  justify-content: center;
  text-align: center;
}
.num{
     text-decoration: none;
    list-style: none;
    background-color: #D6ECF6;
    border-radius: 50px;
    justify-content: center;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
   
}