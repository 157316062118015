.container {
    background-image: url(https://i.ytimg.com/vi/kWFZQBUFWkc/maxresdefault.jpg);
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
    }

  .title {
    position: absolute;
    left:0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.561);
    color: #66fcf1;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    text-shadow:3px 3px rgb(22, 19, 19);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    }
    .btn{
        margin-top: 100px;
        background:linear-gradient(to bottom, #7ED5CD 5%, #9ccec9ad 100%);
        background-color:#7ED5CD;
        border-radius:21px;
        border: none;
        display:inline-block;
        cursor:pointer;
        color:#ffffff;
        font-weight: 500;
        font-size:20px;
        padding:9px 33px;
        text-decoration:none;
        text-shadow:0px 0px 0px #467772;
    }
    